<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
        "
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.limit") }}
        </h5>
      </div>
    </div>
    <div class="text-center d-block width-100">
      <div>
        <span>{{ $t("message.payment_report_limit") }}</span>
      </div>
      <div class="justify-content-center mt-4 d-flex">
        <el-input-number
          v-model="num"
          controls-position="right"
          :min="1"
          :disabled="is_update == false"
          class="mr-3"
        ></el-input-number>

        <el-button
          type="primary"
          icon="el-icon-edit"
          v-if="!is_update"
          @click="change()"
          >{{ $t("message.update") }}</el-button
        >
        <el-button type="success" class="px-4" @click="save(num)" v-if="is_update">{{
          $t("message.create")
        }}</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      selectedModel: "",
      modalDelet: false,
      is_update: false,
      num: null,
    };
  },

  computed: {
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async mounted() {
    this.fetchData();
    await this.updateList().then((res) => {
      this.num = res.limit;
    });
  },

  methods: {
    ...mapActions({
      updateList: "reportLimit/index",
      store: "reportLimit/store",
    }),
    async fetchData() {
      if (!this.loading) {
        this.loading = true;
        await this.updateList().catch((err) => {
          this.loading = false;
        });
      }
    },
    fetchDelete(model) {
      this.selectedModel = model;
      this.modalDelet = true;
    },
    destroy(obj) {
      this.loading = true;
      this.delete(obj.id)
        .then((res) => {
          this.$alert(res);
          this.loading = false;
          this.modalDelet = false;
          this.fetchData();
        })
        .catch((err) => {
          this.$alert(err);
        });
    },
    fetchEmpty() {
      this.empty();
    },
    closeModal() {
      this.modalDelet = false;
    },
    change() {
      this.is_update = true;
    },
    save(val) {
      this.is_update = false;
      this.store({ limit: val });
    },
  },
};
</script>
